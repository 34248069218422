import React from "react"

import Layout from "../components/layout"
import Head from "../components/Head"
import { graphql } from "gatsby"
import "./faq-template.scss"

export default function FaqTemplatePage({ data }) {
  const post = data.markdownRemark
  const date = post.frontmatter.date
  const country = post.frontmatter.country
  const hotLine = data.site.siteMetadata.countries[country]['hotLine']
  return (
    <Layout
      country={country}
      hotLine={hotLine}
    >
      <Head
        lang={country}
        title={"FAQ Allsports " + date}
        slug={post.fields.slug}
      />
      <section className="faq-template">
        <h1>{post.frontmatter.title}</h1>
        <small>{date}</small>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query FaqQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY")
        country
      }
    }
    site {
      siteMetadata {
        countries {
           by {
             hotLine {
               tel
               title
             }
          }
          am {
             hotLine {
               tel
               title
             }
          }
          ru {
             hotLine {
               tel
               title
             }
          }
          lt {
            hotLine {
              tel
              title
            }
          }
        }
      }
    },
  }
`
